/* resets */

*,
*:before,
*:after {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

/* ---- */

html {
  scroll-behavior: smooth;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: clamp(16px, calc(14px + 0.3vw), 20px);
  color: var(--gray1);
  line-height: 1.4;
}

*:focus {
  outline-color: var(--gray5);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
